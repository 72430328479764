import * as React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ReactNode} from 'react';

interface ToolTipProps {
    children: ReactNode;
    id: string;
    placement?: `auto` | `top` | `bottom` | `left` | `right`;
    shown?: boolean;
    title: string;
}

export const ToolTip = ({children, id, placement = `top`, shown = true, title}: ToolTipProps) => {
    /**
     * Template
     */
    if (!shown) {
        return <span>{children}</span>;
    }
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip id={id}>{title}</Tooltip>}
                placement={placement}
            >
                <div className="d-inline disabled-button-wrapper">{children}</div>
            </OverlayTrigger>
        </>
    );
};
